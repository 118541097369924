<template>
<div>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中">
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar />
      <div class="site-content__wrapper" :style="{ 'min-height': documentClientHeight + 'px' }">
        <main-content v-if="!$store.state.common.contentIsNeedRefresh" />
      </div>
    </template>
  </div>
</div>
</template>

<script>
import MainNavbar from './main-navbar'
import MainSidebar from './main-sidebar'
import MainContent from './main-content'
export default {
  provide () {
    return {
      // 刷新
      refresh () {
        this.$store.commit('common/updateContentIsNeedRefresh', true)
        this.$nextTick(() => {
          this.$store.commit('common/updateContentIsNeedRefresh', false)
        })
      }
    }
  },
  data () {
    return {
      loading: true,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false
      // loading: false
    }
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  computed: {
    documentClientHeight: {
      get () { return this.$store.state.common.documentClientHeight },
      set (val) { this.$store.commit('common/updateDocumentClientHeight', val) }
    },
    sidebarFold: {
      get () { return this.$store.state.common.sidebarFold }
    },
    currentUser: {
      get () { return this.$store.state.user.currentUser },
      set (val) { this.$store.commit('user/updateCurrentUser', val) }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap },
      set (val) { this.$store.commit('common/updateDictTypeMap', val) }
    }
  },
  created () {
    this.getUserInfo()
    this.getDictInfo()
    // this.getAreaList()
    this.getGiveAreaList()
  },
  mounted () {
    this.resetDocumentClientHeight()
    // this.getDataList()
  },
  methods: {
    // 重置窗口可视高度
    resetDocumentClientHeight () {
      this.documentClientHeight = document.documentElement['clientHeight']
      window.onresize = () => {
        this.documentClientHeight = document.documentElement['clientHeight']
      }
    },
    // 获取当前管理员信息
    getUserInfo () {
      this.$http({
        url: this.$http.adornUrl('/system/user/getCurrentUser'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.loading = false
        this.currentUser = data
      })
    },
    // 获取基础数据字典信息
    getDictInfo () {
      this.$http({
        url: this.$http.adornUrl('/dict/findDictAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let statusMap = [
          { id: 1, name: '启用' },
          { id: 2, name: '禁用' }
        ]
        let yesNoMap = [
          { id: 1, name: '是' },
          { id: 2, name: '否' }
        ]
        let dispatchStatusMap = [
          { id: 1, name: '未调度' },
          { id: 2, name: '已调度' }
        ]
        let orderType = [
          { id: 1, name: '专车' },
          { id: 2, name: '拼车' },
          { id: 3, name: '零担' }
        ]
        let orderStatusMap = [
          { id: 0, name: '未提交' },
          { id: 1, name: '待审核' },
          { id: 2, name: '调度中' },
          { id: 3, name: '已调度' },
          { id: 4, name: '待出车' },
          { id: 5, name: '已出车' },
          { id: 6, name: '已到达' },
          { id: 7, name: '已装货' },
          { id: 15, name: '卸货到场' },
          { id: 8, name: '已完成' },
          { id: 9, name: '异常结束' },
          { id: 10, name: '审核拒绝' },
          { id: 11, name: '订单退回' },
          { id: 12, name: '已取消' }
        ]
        let planStatusMap = [
          { id: 0, name: '已取消' },
          { id: 1, name: '未发布' },
          { id: 2, name: '已发布' },
          { id: 4, name: '已接单' },
          { id: 5, name: '进行中' },
          { id: 8, name: '已完成' }
          // { id: 9, name: '审核通过' }
        ]
        let abnormalStatusMap = [
          { id: 1, name: '已处理' },
          { id: 2, name: '未处理' }
        ]
        let realTypeMap = [
          { id: 1, name: '个人认证' },
          { id: 2, name: '企业认证' },
          { id: 3, name: '加入企业' }
        ]
        let marketTrackTypeMap = [
          { id: 1, name: '短信' },
          { id: 2, name: '微信' },
          { id: 3, name: '电话' },
          { id: 4, name: '其他' }
        ]
        data['status'] = statusMap
        data['abnormalStatus'] = abnormalStatusMap
        data['yes_no'] = yesNoMap
        data['orderStatusMap'] = orderStatusMap
        data['orderType'] = orderType
        data['planStatus'] = planStatusMap
        data['dispatchStatus'] = dispatchStatusMap
        data['realType'] = realTypeMap
        data['marketTrackType'] = marketTrackTypeMap
        this.dictTypeMap = data
        this.getEntranceType()
      }).then(() => {
      })
    },
    // 获取提货省市区信息
    getAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.$store.state.areaList = data
      })
    },
    // 获取报关方式
    getEntranceType () {
      this.$http({
        url: this.$http.adornUrl(`/entranceType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dictTypeMap['entrance_type'] = data
      })
    },
    getGiveAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findGiveList`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // this.$store.state.getGiveAreaList = data
        this.$store.state.areaList = data
      })
    }
  }
}
</script>
<style lang="less">
.memorandum  {
  .el-dialog {
    margin-top: 0px !important;
    .el-dialog__header {
        padding: 10px 20px 10px;
    }
    .el-dialog__body {
        padding: 0px 20px;
    }
    .el-dialog__footer {
      padding: 10px 20px 10px;
    }
    .el-button {
      height: 30px;
      line-height: 0;
      border-radius: 0;
    }
    .el-pagination {
        text-align: right;
        margin-top: 15px;
    }
  }
}
</style>
